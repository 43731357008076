import React from 'react';
import classNames from 'classnames';
import CustomLink from './CustomLink';
import logo from '../images/logo-sic-triangle.png';
import sponsor1 from '../images/sponsor1.png';
import sponsor2 from '../images/sponsor2.png';
import sponsor3 from '../images/sponsor3.png';
import sponsor5 from '../images/sponsor5.png';
import sponsor6 from '../images/sponsor6.png';
import sponsor7 from '../images/sponsor7.png';
import sponsor8 from '../images/sponsor8.png';
import dr1 from '../images/dr-1.png';
import dr2 from '../images/dr-2.png';
import dr3 from '../images/dr-3.png';

const SpecialsBar = ({ className }) => (
  <div className={classNames('specials-bar', className)}>
    <div className="specials-bar-inner">
      <div className="new-doctors">
        <div className="new-doctors__start">
          <figure className="new-doctors__logo">
            <img src={logo} alt="SIC logo" className="fanduel-box-logo" />
          </figure>
          <CustomLink to="/about">MEET THE DOCTORS</CustomLink>
        </div>
        <div className="new-doctors__end">
          <div className="new-doctors__title">
            Insider Knowledge From Pro Sports Team Docs With Over 100 Years of Experience!
          </div>
          <div className="new-doctors__photos">
            <div className="new-doctors__photo">
              <figure>
                <img src={dr1} alt="Doctor" />
              </figure>
              <span>Dr. Chao</span>
            </div>
            <div className="new-doctors__photo">
              <figure>
                <img src={dr2} alt="Doctor" />
              </figure>
              <span>Dr. Hizon</span>
            </div>
            <div className="new-doctors__photo">
              <figure>
                <img src={dr3} alt="Doctor" />
              </figure>
              <span>Dr. Hefferon</span>
            </div>
          </div>
        </div>
      </div>
      {/*
		<div className="specials-bar-start">
			<div className="specials-bar-tweet">
				“No one has better perspective on injuries, how they happen and what they mean, than Dr. Chao. It’s Impressive!”
			</div>
			<div className="specials-bar-author">
				<div>
					<strong>Ian Rapoport</strong>
					<span>| National Insider for NFL Network</span>
				</div>

				<address>
					<span>@IanRapoport</span>
					<img src={ icon2 } alt="Twitter logo" />
				</address>
			</div>
		</div>
		*/}
      <div className="specials-bar-features">
        <div className="specials-bar-title">PROUD TO BE FEATURED ON</div>
        <div className="specials-bar-feats">
          <div className="specials-bar-logos">
            <a
              href="https://foxsportsradio.iheart.com/content/2021-01-19-former-nfl-team-doctor-breaks-down-patrick-mahomes-status-for-afc-title/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={sponsor6} alt="Banner" width="60" />
            </a>
            <a
              href="https://www.nytimes.com/2018/12/13/sports/nfl-injuries-pro-football-doc.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={sponsor1} alt="Banner" width="200" />
            </a>
            <a
              href="https://www.cbssports.com/podcasts/fantasy-football-today-podcast/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={sponsor7} alt="Banner" width="160" />
            </a>
          </div>
          <div className="specials-bar-logos">
            <a href="https://www.siriusxm.com/channels/siriusxm-nfl-radio" target="_blank" rel="noopener noreferrer">
              <img src={sponsor2} alt="Banner" width="140" />
            </a>
            <a
              href="https://www.cnbc.com/video/2019/01/14/meet-footballs-armchair-doctor.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={sponsor3} alt="Banner" width="150" />
            </a>
            <a
              href="https://www.forbes.com/sites/michaellore/2019/12/20/the-rise-of-dr-david-chao-aka-pro-football-doc/#1b5a123c27e6"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={sponsor5} alt="Banner" width="100" />
            </a>
            <a href="https://bleacherreport.com/nfl-injuries" target="_blank" rel="noopener noreferrer">
              <img src={sponsor8} alt="Banner" width="60" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default SpecialsBar;
