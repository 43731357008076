import {
  Avatar,
  Box,
  Card,
  Chip,
  CircularProgress,
  Container,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  makeStyles,
} from '@material-ui/core';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';

import { getSeason } from '../helpers/session';
import { useEffect, useState } from 'react';
import { API_URL } from '../config.js';
import axios from 'axios';
// import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
// import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

const useStyles = makeStyles({
  img: {
    objectFit: 'contain',
  },
});

const getColor = (value) => {
  if (value > 85) {
    return '#17C05D';
  } else if (value < 85 && value > 70) {
    return '#F6C035';
  } else {
    return '#EC4545';
  }
};

function InjuryEdgeScreen(props) {
  const isSmallerScreen = isWidthDown('sm', props.width);
  const classes = useStyles();
  const currentSeason = getSeason();
  const [weeks, setWeeks] = useState([]);
  const [games, setGames] = useState([]);
  const [loading, setLoading] = useState(true);

  const [currentLeague, setCurrentLeague] = useState('NFL');
  const [currentWeek, setCurrentWeek] = useState('');
  const [currentWeekNum, setCurrentWeekNum] = useState(0);
  const [previousWeekId, setPreviousWeekId] = useState('');

  useEffect(() => {
    axios.get(`${API_URL}/scheduleTimeline/season/${currentSeason}`).then(({ data }) => {
      const { scheduleTimelines } = data.data;
      const currentWeek = scheduleTimelines.find((week) => week.isCurrentWeek) || scheduleTimelines[0];
      const _previousWeekId = scheduleTimelines.find((week) => week.order === currentWeek.order - 1)?._id;
      setWeeks(scheduleTimelines);
      setCurrentWeek(currentWeek._id);
      setCurrentWeekNum(currentWeek.order);
      setPreviousWeekId(_previousWeekId);
    });
  }, [currentSeason]);

  useEffect(() => {
    setGames([]);

    async function execute() {
      const currentWeekGames = await axios.post(`${API_URL}/injuryIndex`, {
        scheduleTimelineId: currentWeek,
        leagueType: currentLeague,
        currentSeason,
      });

      let previousWeekGames;

      if (currentWeekNum > 1 && previousWeekId) {
        const previousWeekGamesResponse = await axios.post(`${API_URL}/injuryIndex`, {
          scheduleTimelineId: previousWeekId,
          leagueType: currentLeague,
          currentSeason,
        });

        previousWeekGames = previousWeekGamesResponse?.data.data.injuryIndexList[0].games;
      }

      const _games = currentWeekGames.data.data.injuryIndexList[0].games.map((game) => {
        const isHomeAdvantage = game.homeTeam.teamIndex > game.visitingTeam.teamIndex;
        const advantage = isHomeAdvantage
          ? (game.homeTeam.teamIndex - game.visitingTeam.teamIndex).toFixed(1)
          : (game.visitingTeam.teamIndex - game.homeTeam.teamIndex).toFixed(1);

        const previousHomeTeam = previousWeekGames.find((previousGame) => {
          return (
            previousGame.homeTeam.teamId === game.homeTeam.teamId ||
            previousGame.visitingTeam.teamId === game.homeTeam.teamId
          );
        });

        const isPreviousHomeTeamVisiting = previousHomeTeam?.visitingTeam.teamId === game.homeTeam.teamId;
        const previousHomeTeamIndex = isPreviousHomeTeamVisiting
          ? previousHomeTeam?.visitingTeam.teamIndex
          : previousHomeTeam?.homeTeam.teamIndex;

        const previousVisitingTeam = previousWeekGames.find((previousGame) => {
          return (
            previousGame.homeTeam.teamId === game.visitingTeam.teamId ||
            previousGame.visitingTeam.teamId === game.visitingTeam.teamId
          );
        });

        const isPreviousVisitingTeamVisiting = previousVisitingTeam?.visitingTeam.teamId === game.visitingTeam.teamId;
        const previousVisitingTeamIndex = isPreviousVisitingTeamVisiting
          ? previousVisitingTeam?.visitingTeam.teamIndex
          : previousVisitingTeam?.homeTeam.teamIndex;

        let homeTeamPoints = game?.homeTeam.points && parseFloat(game?.homeTeam.points);
        let visitingTeamPoints = game?.visitingTeam.points && parseFloat(game?.visitingTeam.points);

        if (!homeTeamPoints && visitingTeamPoints) {
          homeTeamPoints = visitingTeamPoints * -1;
        }

        if (!visitingTeamPoints && homeTeamPoints) {
          visitingTeamPoints = homeTeamPoints * -1;
        }


        return {
          totalPoints: game.OU,
          homeTeam: {
            name: game.homeTeamDetails.name,
            logoUrl: game.homeTeamDetails.logoUrl,
            abbreviation: game.homeTeamDetails.abbreviation,
            teamIndex: game.homeTeam.teamIndex,
            change: previousHomeTeamIndex
              ? (parseFloat(game.homeTeam.teamIndex) - parseFloat(previousHomeTeamIndex)).toFixed(1)
              : '-',
            offenseIndex: game.homeTeam.offenseIndex,
            defenseIndex: game.homeTeam.defenseIndex,
            points: game.homeTeam.points,
          },
          visitingTeam: {
            name: game.visitingTeamDetails.name,
            logoUrl: game.visitingTeamDetails.logoUrl,
            abbreviation: game.visitingTeamDetails.abbreviation,
            teamIndex: game.visitingTeam.teamIndex,
            change: previousVisitingTeamIndex
              ? (parseFloat(game.visitingTeam.teamIndex) - parseFloat(previousVisitingTeamIndex)).toFixed(1)
              : '-',
            offenseIndex: game.visitingTeam.offenseIndex,
            defenseIndex: game.visitingTeam.defenseIndex,
            points: game.visitingTeam.points,
          },
          advantage: {
            name: isHomeAdvantage ? game.homeTeamDetails.name : game.visitingTeamDetails.name,
            logoUrl: isHomeAdvantage ? game.homeTeamDetails.logoUrl : game.visitingTeamDetails.logoUrl,
            abbreviation: isHomeAdvantage ? game.homeTeamDetails.abbreviation : game.visitingTeamDetails.abbreviation,
            advantage,
            points: isHomeAdvantage ? homeTeamPoints : visitingTeamPoints,
          },
        };
      });
      setLoading(false);
      setGames(_games.sort((a, b) => b.advantage.advantage - a.advantage.advantage));
    }

    if (currentWeek && currentLeague && currentSeason && previousWeekId) {
      execute();
    }
  }, [currentLeague, currentSeason, currentWeek, currentWeekNum, previousWeekId]);

  return (
    <Container
      style={{
        marginTop: 50,
        marginBottom: 50,
        overflowX: 'auto',
        padding: 0,
      }}
    >
      <Typography
        variant="h4"
        component="h1"
        gutterBottom
        style={{
          textAlign: 'center',
          fontWeight: 'bold',
        }}
      >
        The Injury Edge: Week {currentWeekNum}
      </Typography>

      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: 20,
          marginTop: 40,
        }}
      >
        {games?.length > 0 &&
          games.map((game) => (
            <Card variant="outlined">
              <Table>
                <TableHead>
                  <TableRow
                    style={{
                      backgroundColor: '#333333',
                    }}
                  >
                    <TableCell
                      align="center"
                      style={{
                        color: 'white',
                        padding: isSmallerScreen ? 5 : 12,
                        fontSize: isSmallerScreen ? 11 : 14,
                      }}
                    >
                      Matchup
                    </TableCell>
                    <TableCell align="center"></TableCell>
                    <TableCell
                      align="center"
                      style={{
                        color: 'white',
                        padding: isSmallerScreen ? 5 : 12,
                        fontSize: isSmallerScreen ? 11 : 14,
                      }}
                    >
                      SIC Score
                    </TableCell>
                    {/* <TableCell align="center">Change</TableCell> */}
                    <TableCell
                      align="center"
                      style={{
                        color: 'white',
                        padding: isSmallerScreen ? 5 : 12,
                        fontSize: isSmallerScreen ? 11 : 14,
                      }}
                    >
                      Offense
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        color: 'white',
                        padding: isSmallerScreen ? 5 : 12,
                        fontSize: isSmallerScreen ? 11 : 14,
                      }}
                    >
                      Defense
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow
                    style={game?.visitingTeam?.name === game?.advantage?.name ? { backgroundColor: '#f2f8f2' } : {}}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      align="center"
                      style={{
                        padding: isSmallerScreen ? 5 : 12,
                      }}
                    >
                      <Box
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          flexDirection: 'column',
                        }}
                      >
                        {/* <Typography
                          variant="caption"
                          style={{
                            fontWeight: 'bold',
                            fontSize: isSmallerScreen ? 11 : 14,
                          }}
                        >
                          VISITING
                        </Typography> */}
                        <Avatar
                          variant="square"
                          alt={game?.visitingTeam?.name}
                          src={game?.visitingTeam?.logoUrl}
                          classes={{
                            img: classes.img,
                          }}
                          //   style={game?.visitingTeam?.name === game?.advantage?.name ? { width: 80, height: 80 } : {}}
                        />
                        <Typography
                          style={{
                            fontWeight: 'bold',
                            fontSize: isSmallerScreen ? 11 : 14,
                          }}
                        >
                          {game?.visitingTeam?.abbreviation}{' '}
                          {game?.visitingTeam?.name === game?.advantage?.name && game?.advantage.points}
                        </Typography>
                      </Box>
                      <Chip
                        size="small"
                        label={`TOTAL PTS ${game?.totalPoints}`}
                        style={{
                          top: 25,
                          position: 'relative',
                          background: 'white',
                          border: '1px solid lightgray',
                          fontWeight: 'bold',
                          marginTop: -18,
                        }}
                      />
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      align="center"
                      style={{
                        padding: isSmallerScreen ? 5 : 12,
                      }}
                    >
                      {game?.visitingTeam?.name === game?.advantage?.name && (
                        <Box
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexDirection: 'column',
                          }}
                        >
                          <Typography
                            style={{
                              fontWeight: 'bold',
                              fontSize: isSmallerScreen ? 14 : 24,
                            }}
                          >
                            {game?.advantage?.advantage}
                          </Typography>
                          <Typography
                            variant="caption"
                            style={{
                              fontSize: isSmallerScreen ? 9 : 14,
                            }}
                          >
                            ADVANTAGE
                          </Typography>
                        </Box>
                      )}
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      align="center"
                      style={{
                        padding: isSmallerScreen ? 5 : 12,
                      }}
                    >
                      <Box
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <Chip
                          label={game?.visitingTeam?.teamIndex}
                          style={{
                            backgroundColor: getColor(game?.visitingTeam?.teamIndex),
                            color: 'white',
                            fontWeight: 'bold',
                          }}
                          size={isSmallerScreen ? 'small' : 'medium'}
                        />
                      </Box>
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      align="center"
                      style={{
                        padding: isSmallerScreen ? 5 : 12,
                      }}
                    >
                      <Box
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <Chip
                          label={game?.visitingTeam?.offenseIndex}
                          style={{
                            backgroundColor: getColor(game?.visitingTeam?.offenseIndex),
                            color: 'white',
                            fontWeight: 'bold',
                          }}
                          size={isSmallerScreen ? 'small' : 'medium'}
                        />
                      </Box>
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      align="center"
                      style={{
                        padding: isSmallerScreen ? 5 : 12,
                      }}
                    >
                      <Box
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <Chip
                          label={game?.visitingTeam?.defenseIndex}
                          style={{
                            backgroundColor: getColor(game?.visitingTeam?.defenseIndex),
                            color: 'white',
                            fontWeight: 'bold',
                          }}
                          size={isSmallerScreen ? 'small' : 'medium'}
                        />
                      </Box>
                    </TableCell>
                  </TableRow>
                  <TableRow
                    style={game?.homeTeam?.name === game?.advantage?.name ? { backgroundColor: '#f2f8f2' } : {}}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      align="center"
                      style={{
                        padding: isSmallerScreen ? 5 : 12,
                      }}
                    >
                      <Box
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          flexDirection: 'column',
                        }}
                      >
                        {/* <Typography
                          variant="caption"
                          style={{
                            fontWeight: 'bold',
                            fontSize: isSmallerScreen ? 11 : 14,
                          }}
                        >
                          HOME
                        </Typography> */}
                        <Box height={20}></Box>
                        <Avatar
                          variant="square"
                          alt={game?.homeTeam?.name}
                          src={game?.homeTeam?.logoUrl}
                          classes={{
                            img: classes.img,
                          }}
                          //   style={game?.homeTeam?.name === game?.advantage?.name ? { width: 80, height: 80 } : {}}
                        />
                        <Typography
                          style={{
                            fontWeight: 'bold',
                            fontSize: isSmallerScreen ? 11 : 14,
                          }}
                        >
                          {game?.homeTeam?.abbreviation}{' '}
                          {game?.homeTeam?.name === game?.advantage?.name && game?.advantage.points}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      align="center"
                      style={{
                        padding: isSmallerScreen ? 5 : 12,
                      }}
                    >
                      {game?.homeTeam?.name === game?.advantage?.name && (
                        <Box
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexDirection: 'column',
                          }}
                        >
                          <Typography
                            style={{
                              fontWeight: 'bold',
                              fontSize: isSmallerScreen ? 14 : 24,
                            }}
                          >
                            {game?.advantage?.advantage}
                          </Typography>
                          <Typography
                            variant="caption"
                            style={{
                              fontSize: isSmallerScreen ? 9 : 14,
                            }}
                          >
                            ADVANTAGE
                          </Typography>
                        </Box>
                      )}
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      align="center"
                      style={{
                        padding: isSmallerScreen ? 5 : 12,
                      }}
                    >
                      <Box
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <Chip
                          label={game?.homeTeam?.teamIndex}
                          style={{
                            backgroundColor: getColor(game?.homeTeam?.teamIndex),
                            color: 'white',
                            fontWeight: 'bold',
                          }}
                          size={isSmallerScreen ? 'small' : 'medium'}
                        />
                      </Box>
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      align="center"
                      style={{
                        padding: isSmallerScreen ? 5 : 12,
                      }}
                    >
                      <Box
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <Chip
                          label={game?.homeTeam?.offenseIndex}
                          style={{
                            backgroundColor: getColor(game?.homeTeam?.offenseIndex),
                            color: 'white',
                            fontWeight: 'bold',
                          }}
                          size={isSmallerScreen ? 'small' : 'medium'}
                        />
                      </Box>
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      align="center"
                      style={{
                        padding: isSmallerScreen ? 5 : 12,
                      }}
                    >
                      <Box
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <Chip
                          label={game?.homeTeam?.defenseIndex}
                          style={{
                            backgroundColor: getColor(game?.homeTeam?.defenseIndex),
                            color: 'white',
                            fontWeight: 'bold',
                          }}
                          size={isSmallerScreen ? 'small' : 'medium'}
                        />
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Card>
          ))}
      </Box>

      {loading && (
        <Box
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: 50,
            marginBottom: 50,
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </Container>
  );
}

export default withWidth()(InjuryEdgeScreen);
