import React, { Component, Suspense } from 'react';
import axios from 'axios';
import { Route, Switch, BrowserRouter, Redirect } from 'react-router-dom';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import GlobalProvider from './providers/GlobalProvider';
import Header from './components/Header';
import Footer from './components/Footer';
import SubscriptionFlow from './components/SubscriptionFlow';
import LoadingSplash from './components/LoadingSplash';
import EmbedHeader from './components/EmbedHeader';
import {
  setToken,
  getToken,
  setRefreshToken,
  getRefreshToken,
  setAdmin,
  forgetToken,
  forgetRefreshToken,
} from './helpers/session';
import { APP_VERSION, API_URL } from './config.js';
import './styles/app.sass';
import './styles/v3.sass';
import './styles/v3-responsive.sass';
import './styles/v4-desktop.sass';
import './styles/v5.sass';
import './styles/v5-responsive.sass';
import ContentRestrictionModal from './components/ContentResrictionModal/ContentRestrictionModal';
import HomeScreen from './screens/HomeScreen';
import RunConverge from './components/RunConverge';
import InjuryEdgeScreen from './screens/InjuryEdgeScreen';

const AngelFireScreen = React.lazy(() => import('./screens/AngelFire'));
const LandingScreen = React.lazy(() => import('./screens/LandingScreen'));
const DashboardScreen = React.lazy(() => import('./screens/DashboardScreen'));
const ArticlesScreen = React.lazy(() => import('./screens/ArticlesScreen'));
const AboutScreen = React.lazy(() => import('./screens/AboutScreen'));
const AccountScreen = React.lazy(() => import('./screens/AccountScreen'));
const VideosScreen = React.lazy(() => import('./screens/VideosScreen'));
const InjuriesScreen = React.lazy(() => import('./screens/InjuriesScreen'));
const LoginScreen = React.lazy(() => import('./screens/LoginScreen'));
const ForgotPasswordScreen = React.lazy(() => import('./screens/ForgotPasswordScreen'));
const RegisterScreen = React.lazy(() => import('./screens/RegisterScreen'));
const UpgradeScreen = React.lazy(() => import('./screens/UpgradeScreen'));
const PicksScreen = React.lazy(() => import('./screens/PicksScreen'));
const ScoresScreen = React.lazy(() => import('./screens/ScoresScreen'));
const PostScreen = React.lazy(() => import('./screens/PostScreen'));
const PageScreen = React.lazy(() => import('./screens/PageScreen'));
const GameDayScreen = React.lazy(() => import('./screens/GameDayScreen'));
const GamesDayScreen = React.lazy(() => import('./screens/GamesDayScreen'));
const ArticleScreen = React.lazy(() => import('./screens/ArticleScreen'));
const TeamsScreen = React.lazy(() => import('./screens/TeamsScreen'));
const TeamScreen = React.lazy(() => import('./screens/TeamScreen'));
const PlayersScreen = React.lazy(() => import('./screens/PlayersScreen'));
const PlayerScreen = React.lazy(() => import('./screens/PlayerScreen'));
const FantasyTeamScreen = React.lazy(() => import('./screens/FantasyTeamScreen'));
const FantasyTeamsScreen = React.lazy(() => import('./screens/FantasyTeamsScreen'));
const FantasyPlayersScreen = React.lazy(() => import('./screens/FantasyPlayersScreen'));
const InjuryTickerScreen = React.lazy(() => import('./screens/InjuryTickerScreen'));
const PodcastsScreen = React.lazy(() => import('./screens/PodcastsScreen'));
const PrivacyScreen = React.lazy(() => import('./screens/PrivacyScreen'));
const TermsScreen = React.lazy(() => import('./screens/TermsScreen'));
const EmbedScreen = React.lazy(() => import('./screens/EmbedScreen'));
const GameEmbedScreen = React.lazy(() => import('./screens/GameEmbedScreen'));
const EmbedWidgetScreen = React.lazy(() => import('./screens/EmbedWidgetScreen'));
const NflFantasyScreen = React.lazy(() => import('./screens/NflFantasyScreen'));
const NbaFantasyScreen = React.lazy(() => import('./screens/NbaFantasyScreen'));
const MlbFantasyScreen = React.lazy(() => import('./screens/MlbFantasyScreen'));
const PollsScreen = React.lazy(() => import('./screens/PollsScreen'));
const Error404Screen = React.lazy(() => import('./screens/Error404Screen'));
const CheckoutScreen = React.lazy(() => import('./screens/CheckoutScreen'));
const ConfirmationScreen = React.lazy(() => import('./screens/ConfirmationScreen'));

const theme = createTheme({
  palette: {
    primary: {
      main: '#1876D2',
    },
  },
  typography: {
    fontFamily: ['Montserrat', 'sans-serif'],
  },
});

axios.interceptors.request.use((config) => {
  // const selectedLeague = localStorage.getItem('pfd_selected_league_2') ? localStorage.getItem('pfd_selected_league_2') : 'nfl';
  const selectedLeague = 'nfl';

  config.headers['x-access-token'] = getToken();
  config.headers['apikey'] = '3f51e17cd56feaa03b7a9505381219f3d64d4634';

  if (typeof config.headers['league_type'] === 'undefined') {
    config.headers['league_type'] = selectedLeague;
  }

  if (config.headers['league_type'] === '') {
    delete config.headers['league_type'];
  }

  config.headers['embedded_key'] = '79063581d48cfeecf12fe89405db23932020';

  return config;
});

export default class App extends Component {
  // intervalId = 0;

  constructor() {
    super();

    this.state = {
      profile: null,
      showStreamingButton: false,
      showNotificationsNotice: localStorage.getItem('pfd_notifications_dismissed_2') !== 'yes',
    };
  }

  componentDidMount() {
    setTimeout(() => document.querySelector('.splash-screen').remove(), 1000);

    if (window.location.href.indexOf('oauth_token') !== -1) {
      let { search } = window.location;
      search = search.substring(1).split('&');
      const searchParams = {};
      for (let i in search) {
        const pieces = search[i].split('=');
        searchParams[pieces[0]] = pieces[1] ? pieces[1] : '';
      }

      if (searchParams.oauth_token && searchParams.oauth_verifier) {
        setTimeout(() => {
          axios
            .post(
              API_URL +
                '/auth/authenticate/twitter?oauth_token=' +
                searchParams.oauth_token +
                '&oauth_verifier=' +
                searchParams.oauth_verifier
            )
            .then((response) => {
              if (response.status !== 200) {
                alert(response.statusText);
              } else {
                const { data } = response;

                if (data.status && data.status === 'success' && data.token) {
                  setToken(data.token);
                  setRefreshToken(data.refreshToken);
                  setAdmin(data.data.user.roleName === 'Admin');
                  window.location.href = '/';
                } else if (data.message) {
                  // alert(data.message);
                } else {
                  // alert('Twitter Auth Failed');
                }
              }
            });
        }, 1000);
      }
    } else {
      this.onRefreshSession();

      axios
        .get(API_URL + '/user/me')
        .then((response) => {})
        .catch((error) => {
          const refreshToken = getRefreshToken();
          if (error.response && error.response.status.toString() === '401' && refreshToken) {
            axios
              .post(API_URL + '/user/refreshToken', {}, { headers: { 'x-refresh-token': refreshToken } })
              .then((response) => {
                if (response.data && response.data.token && response.data.refreshToken) {
                  setToken(response.data.token);
                  setRefreshToken(response.data.refreshToken);
                  // window.location.reload();
                }
              });
          }
        });

      axios.interceptors.response.use(
        (response) => response,
        (error) => {
          const { pathname } = window.location;
          const status = error.response && error.response.status ? error.response.status : '';

          if (
            status === 401 &&
            pathname !== '/' &&
            pathname.indexOf('/register') === -1 &&
            pathname.indexOf('/login') === -1 &&
            pathname.indexOf('/forgot') === -1 &&
            pathname.indexOf('/404') === -1 &&
            pathname.indexOf('/privacy') === -1 &&
            pathname.indexOf('/terms-of-service') === -1 &&
            pathname.indexOf('/embed') === -1 &&
            pathname.indexOf('/game-embed') === -1 &&
            pathname.indexOf('/updates/') === -1 &&
            pathname.indexOf('/podcasts') === -1 &&
            pathname.indexOf('/about') === -1 &&
            pathname.indexOf('/premium') === -1 &&
            pathname.indexOf('/picks') === -1
          ) {
            let refreshToken = getRefreshToken();

            if (refreshToken !== null && refreshToken !== 'null') {
              axios
                .post(API_URL + '/user/refreshToken', {}, { headers: { 'x-refresh-token': refreshToken } })
                .then((response) => {
                  if (response.data && response.data.token && response.data.refreshToken) {
                    setToken(response.data.token);
                    setRefreshToken(response.data.refreshToken);
                    // window.location.reload();
                  }
                })
                .catch((error) => {
                  forgetToken();
                  forgetRefreshToken();
                });
            } else {
              forgetToken();
              forgetRefreshToken();
            }
          }

          return Promise.reject(error);
        }
      );
    }

    this.onRefreshSession();

    // this.intervalId = setInterval(this.onStreamingCheck, 10000);

    window.sidedSso = () => {
      axios.get(API_URL + '/sided/sso').then(({ data }) => {
        localStorage.setItem('L2b5N2b9M0', data.data.sidedUser.id);
        localStorage.setItem('L2b5N2b9M_at', data.data.sidedToken);
        localStorage.setItem('L2b5N2b9M_rt', data.data.sidedRefreshToken);
        const sidedSsoRedirectTo = localStorage.getItem('sided_sso_redirect_to');
        window.location.href = sidedSsoRedirectTo || '/polls';
      });
    };
  }

  // componentWillUnmount() {
  //   if (this.intervalId) {
  //     clearInterval(this.intervalId);
  //   }
  // }

  // onStreamingCheck = () => {
  //   axios.get(API_URL + '/video/youtube/liveVideo').then(response => this.setState({ showStreamingButton: response.data.data.isLiveVideo }));
  // }

  onRefreshSession = () => {
    axios.get(API_URL + '/user/me').then(({ data }) => {
      this.setState({
        profile: data.data,
      });
    });
  };

  onCloseModal = () => {
    this.setState({ showNotificationsNotice: false });
    localStorage.setItem('pfd_notifications_dismissed_2', 'yes');
  };

  onScreenChange = () => this.forceUpdate();

  render() {
    const { profile, showStreamingButton, showNotificationsNotice } = this.state;
    const { pathname } = window.location;
    let routeClass =
      'route-' +
      (pathname.indexOf('?') !== -1 ? pathname.substring(0, pathname.indexOf('?')) : pathname)
        .substring(1)
        .replace('/', '-')
        .replace('/', '-');
    if (routeClass.trim() === 'route-forgot-') {
      routeClass = 'route-forgot';
    }
    console.log('routeClass ', routeClass);
    routeClass = routeClass === 'route-' ? 'route-landing' : routeClass;
    routeClass = routeClass.indexOf('/home') !== -1 ? 'route-home' : routeClass;
    routeClass = routeClass.indexOf('/players/') !== -1 ? 'route-team-players' : routeClass;
    routeClass = routeClass.indexOf('teams-') !== -1 ? 'route-team' : routeClass;
    routeClass = routeClass.indexOf('embed-widget') !== -1 ? 'route-embed-widget' : routeClass;
    routeClass =
      (routeClass.indexOf('article-') !== -1 ? 'route-article' : routeClass) + (getToken() ? '' : ' guest-user');
    routeClass = routeClass.replace('nfl-embed', 'embed');
    routeClass = routeClass.replace('nba-embed', 'embed');
    routeClass = routeClass.replace('mlb-embed', 'embed');
    routeClass = routeClass.replace('nfl-game-embed', 'game-embed');
    routeClass = routeClass.replace('nba-game-embed', 'game-embed');
    routeClass = routeClass.replace('mlb-game-embed', 'game-embed');
    console.log('routeClass final', routeClass);
    const bodyClasses = document
      .querySelector('body')
      .className.split(' ')
      .filter((cls) => cls.trim() !== '' && cls.indexOf('body-') === -1 && cls !== 'guest-user');
    bodyClasses.push(routeClass.replace('route-', 'body-'));
    document.querySelector('body').className = bodyClasses
      .map((cls) => cls.replace('body-nfl-updates-', 'body-article body-nfl-updates-'))
      .join(' ');

    return (
      <Suspense fallback={<LoadingSplash />}>
        <MuiThemeProvider theme={theme}>
          <Paper className={'page ' + routeClass.replace('route-', 'page-')} id="page">
            <GlobalProvider>
              <BrowserRouter>
                <RunConverge />
                <Header
                  onRefreshSession={this.onRefreshSession}
                  showStreamingButton={showStreamingButton}
                  profile={profile}
                  onClickEvent={() => this.forceUpdate()}
                />
                <EmbedHeader />
                <Container fixed className={'main-frame ' + routeClass}>
                  <Switch>
                    <Route
                      path="/"
                      exact
                      render={(props) => <LandingScreen {...props} showStreamingButton={showStreamingButton} />}
                    />
                    <Route
                      path="/home"
                      exact
                      render={(props) => <HomeScreen {...props} showStreamingButton={showStreamingButton} />}
                    />

                    <Route path="/contests" exact render={(props) => <AngelFireScreen />} />
                    <Route
                      path="/nfl"
                      exact
                      render={(props) => (
                        <DashboardScreen league="NFL" {...props} showStreamingButton={showStreamingButton} />
                      )}
                    />
                    <Route
                      path="/cfb"
                      exact
                      render={(props) => (
                        <DashboardScreen league="CFB" {...props} showStreamingButton={showStreamingButton} />
                      )}
                    />
                    <Route
                      path="/nba"
                      exact
                      render={(props) => (
                        <DashboardScreen league="NBA" {...props} showStreamingButton={showStreamingButton} />
                      )}
                    />
                    <Route
                      path="/mlb"
                      exact
                      render={(props) => (
                        <DashboardScreen league="MLB" {...props} showStreamingButton={showStreamingButton} />
                      )}
                    />
                    <Route path="/:league/videos" exact component={VideosScreen} />
                    <Route path="/:league/injuries" exact component={InjuriesScreen} />

                    <Route
                      path="/login"
                      exact
                      render={(props) => <LoginScreen {...props} onRefreshSession={this.onRefreshSession} />}
                    />
                    <Route
                      path="/login-fanduel"
                      exact
                      render={(props) => (
                        <LoginScreen trial={true} {...props} onRefreshSession={this.onRefreshSession} />
                      )}
                    />
                    <Route
                      path="/login-founder"
                      exact
                      render={(props) => (
                        <LoginScreen trial={true} free={true} {...props} onRefreshSession={this.onRefreshSession} />
                      )}
                    />
                    <Route path="/forgot" exact component={ForgotPasswordScreen} />

                    <Route
                      path="/register"
                      exact
                      render={(props) => <RegisterScreen {...props} onRefreshSession={this.onRefreshSession} />}
                    />
                    <Route
                      path="/register-fanduel"
                      exact
                      render={(props) => (
                        <RegisterScreen trial={true} {...props} onRefreshSession={this.onRefreshSession} />
                      )}
                    />
                    <Route
                      path="/register-founder"
                      exact
                      render={(props) => (
                        <RegisterScreen trial={true} free={true} {...props} onRefreshSession={this.onRefreshSession} />
                      )}
                    />

                    <Route
                      path="/premium"
                      exact
                      render={(props) => <UpgradeScreen {...props} onScreenChange={this.onScreenChange} />}
                    />
                    <Route
                      path="/checkout"
                      exact
                      render={(props) => <CheckoutScreen {...props} onScreenChange={this.onScreenChange} />}
                    />
                    <Route
                      path="/confirmation"
                      exact
                      render={(props) => <ConfirmationScreen {...props} onScreenChange={this.onScreenChange} />}
                    />
                    <Route path="/picks" exact component={PicksScreen} />

                    <Route path="/matchups" exact component={ScoresScreen} />
                    <Route
                      path="/insights"
                      exact
                      render={(props) => <InjuriesScreen {...props} title="SIC Insights" insightsView={true} />}
                    />
                    <Route path="/injury-edge" exact component={InjuryEdgeScreen} />
                    <Route path="/post/:id" exact component={PostScreen} />
                    <Route path="/page/:slug" exact component={PageScreen} />
                    <Route
                      path="/:league/games"
                      exact
                      render={(props) => <GamesDayScreen {...props} showStreamingButton={showStreamingButton} />}
                    />
                    <Route path="/:league/games/:season/:slug" exact component={GameDayScreen} />
                    <Route path="/:league/updates/:slug" exact component={ArticleScreen} />
                    <Route
                      path="/:league/teams"
                      exact
                      render={(props) => <TeamsScreen {...props} showStreamingButton={showStreamingButton} />}
                    />
                    <Route
                      path="/:league/teams/:slug"
                      exact
                      render={(props) => <TeamScreen {...props} showStreamingButton={showStreamingButton} />}
                    />
                    <Route path="/:league/teams/:slug/injuries" exact component={InjuriesScreen} />
                    <Route
                      path="/:league/players"
                      exact
                      render={(props) => <PlayersScreen {...props} showStreamingButton={showStreamingButton} />}
                    />
                    <Route
                      path="/:league/players/:slug"
                      exact
                      render={(props) => <PlayerScreen {...props} showStreamingButton={showStreamingButton} />}
                    />
                    <Route
                      path="/:league/fantasy-teams"
                      exact
                      render={(props) => <FantasyTeamsScreen {...props} showStreamingButton={showStreamingButton} />}
                    />
                    <Route
                      path="/:league/fantasy-teams/:id"
                      exact
                      render={(props) => <FantasyTeamScreen {...props} showStreamingButton={showStreamingButton} />}
                    />
                    <Route
                      path="/:league/fantasy-players"
                      exact
                      render={(props) => <FantasyPlayersScreen {...props} showStreamingButton={showStreamingButton} />}
                    />
                    <Route
                      path="/:league/player-updates/"
                      exact
                      render={(props) => <ArticlesScreen articleType="docLiveUpdate" {...props} />}
                    />
                    <Route
                      path="/:league/articles"
                      exact
                      render={(props) => <ArticlesScreen articleType="article" {...props} />}
                    />
                    <Route
                      path="/author/:slug"
                      exact
                      render={(props) => <ArticlesScreen {...props} isAuthor={true} />}
                    />
                    <Route
                      path="/account"
                      exact
                      render={(props) => (
                        <AccountScreen
                          {...props}
                          showStreamingButton={showStreamingButton}
                          onRefreshSession={this.onRefreshSession}
                        />
                      )}
                    />
                    <Route
                      path="/about"
                      exact
                      render={(props) => <AboutScreen {...props} showStreamingButton={showStreamingButton} />}
                    />
                    <Route path="/more" exact render={(props) => <ArticlesScreen articleType="more" {...props} />} />
                    <Route path="/injury-ticker" exact render={(props) => <InjuryTickerScreen {...props} />} />
                    <Route path="/podcasts" exact component={PodcastsScreen} />

                    <Route
                      path="/privacy"
                      exact
                      render={(props) => <PrivacyScreen showStreamingButton={showStreamingButton} />}
                    />
                    <Route
                      path="/terms-of-service"
                      exact
                      render={(props) => <TermsScreen showStreamingButton={showStreamingButton} />}
                    />

                    <Route path="/embed" exact component={EmbedScreen} />
                    <Route path="/:league/embed" exact component={EmbedScreen} />
                    <Route path="/game-embed/:id" exact component={GameEmbedScreen} />
                    <Route path="/:league/game-embed/:id" exact component={GameEmbedScreen} />
                    <Route path="/embed-widget/:widgetId/:league" exact component={EmbedWidgetScreen} />

                    <Route path="/nfl/fantasy" exact component={NflFantasyScreen} />
                    <Route path="/nba/fantasy" exact component={NbaFantasyScreen} />
                    <Route path="/mlb/fantasy" exact component={MlbFantasyScreen} />
                    <Route path="/polls" exact component={PollsScreen} />

                    <Route path="/404" exact component={Error404Screen} />
                    <Redirect to="/404" />
                  </Switch>
                </Container>

                <Footer />
                <ContentRestrictionModal />
                <SubscriptionFlow onRefreshSession={this.onRefreshSession} />
              </BrowserRouter>
            </GlobalProvider>

            <Dialog open={showNotificationsNotice && !!profile} className="notify-modal" onClick={this.onCloseModal}>
              <DialogTitle className="dialog-title">
                <div>Turn on your notifications</div>
                <IconButton onClick={this.onTopNoticeClose}>
                  <Icon>close</Icon>
                </IconButton>
              </DialogTitle>
              <DialogContent>
                <Typography component="h4">Be the first to know when big injuries happen</Typography>
                <Typography component="p">
                  Go to your profile and update your notification preferences to get emails and text messages when Doc
                  posts an update on your selected players and teams.
                </Typography>
                <Typography component="p">
                  Make sure you add your phone number to your profile to receive text messages.
                </Typography>
              </DialogContent>
              <DialogActions>
                <Button variant="contained" href="/account" color="primary" onClick={this.onCloseModal}>
                  Go to my profile
                </Button>
              </DialogActions>
              <DialogActions>
                <Button color="primary" onClick={this.onCloseModal}>
                  Do not display in the future
                </Button>
              </DialogActions>
            </Dialog>

            {showNotificationsNotice ? (
              <div className="enable-notifications">
                <a href="/account" onClick={this.onCloseModal}>
                  <Icon>notifications_none</Icon>
                  <span className="text">Enable Notifications To Receive Doc's Updates</span>
                </a>
                <IconButton onClick={this.onCloseModal}>
                  <Icon>close</Icon>
                </IconButton>
              </div>
            ) : null}

            <div className="version-number">{APP_VERSION}</div>
          </Paper>
        </MuiThemeProvider>
      </Suspense>
    );
  }
}
