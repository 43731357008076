import React, { Component } from 'react';
import axios from 'axios';
import GlobalContext from '../contexts/GlobalContext';
import HeroCarouselWidget from '../components/widgets2/HeroCarouselWidget';
import SidePostsWidget from '../components/widgets2/SidePostsWidget';
import GameFeedWidget from '../components/widgets2/GameFeedWidget';
import SicInsightsWidget from '../components/widgets2/SicInsightsWidget';
import VideosFeedWidget from '../components/widgets2/VideosFeedWidget';
import HealthScoreWidget from '../components/widgets2/HealthScoreWidget';
import SpecialsBar from '../components/SpecialsBar';
import LatestNews from '../components/LatestNews';
import CustomLink from '../components/CustomLink';
import { API_URL, SUPPORTED_LEAGUES } from '../config.js';
import { getToken } from '../helpers/session';
import { Container } from '@material-ui/core';

const LockIcon = () => (
  <svg width="31" height="41" viewBox="0 0 31 41" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M27.125 37.0952V17.5714H3.875V37.0952H27.125ZM27.125 13.6667C28.1527 13.6667 29.1383 14.0781 29.865 14.8103C30.5917 15.5426 31 16.5358 31 17.5714V37.0952C31 38.1308 30.5917 39.124 29.865 39.8563C29.1383 40.5886 28.1527 41 27.125 41H3.875C1.72438 41 0 39.2429 0 37.0952V17.5714C0 16.5358 0.408258 15.5426 1.13496 14.8103C1.86166 14.0781 2.84729 13.6667 3.875 13.6667H21.3125V9.7619C21.3125 8.20849 20.7001 6.71871 19.6101 5.62028C18.52 4.52185 17.0416 3.90476 15.5 3.90476C13.9584 3.90476 12.48 4.52185 11.3899 5.62028C10.2999 6.71871 9.6875 8.20849 9.6875 9.7619H5.8125C5.8125 7.17289 6.83314 4.68991 8.6499 2.8592C10.4667 1.02848 12.9307 0 15.5 0C16.7722 0 18.0319 0.252499 19.2072 0.743081C20.3826 1.23366 21.4505 1.95272 22.3501 2.8592C23.2497 3.76567 23.9632 4.84182 24.4501 6.02619C24.9369 7.21055 25.1875 8.47995 25.1875 9.7619V13.6667H27.125ZM15.5 31.2381C14.4723 31.2381 13.4867 30.8267 12.76 30.0944C12.0333 29.3621 11.625 28.3689 11.625 27.3333C11.625 26.2977 12.0333 25.3045 12.76 24.5723C13.4867 23.84 14.4723 23.4286 15.5 23.4286C16.5277 23.4286 17.5133 23.84 18.24 24.5723C18.9667 25.3045 19.375 26.2977 19.375 27.3333C19.375 28.3689 18.9667 29.3621 18.24 30.0944C17.5133 30.8267 16.5277 31.2381 15.5 31.2381Z"
      fill="white"
    />
  </svg>
);

class HomeScreen extends Component {
  constructor() {
    super();

    this.state = {
      loading: true,
    };
  }

  componentDidMount() {
    const { props } = this;
    const { location } = props;

    if (location.hash.indexOf('#/game') !== -1) {
      window.location.href = '/game-embed/' + location.hash.replace('#/game/', '');
      return;
    }

    window.trackEvents('PageView', { title: 'Landing' });
    window.trackEvents('ViewContent', { title: 'Landing' });

    this.loadWidgetsSettings();
    window.landingScreenLoadWidgets = () => setTimeout(() => this.loadWidgetsSettings());
  }

  loadWidgetsSettings = () => {
    const { context, props } = this;
    const { location } = props;
    const { pathname } = location;

    axios
      .get(
        API_URL + '/widget?page=' + (SUPPORTED_LEAGUES.indexOf(pathname) !== -1 ? pathname.replace('/', '') : 'home')
      )
      .then((response) => {
        const { data } = response.data;
        const { widgets } = context;

        const defaults = {
          leagueType: 'all',
          categories: 'all',
          boostedPost: 'no',
          postLimits: 10,
          orientation: 'horizontal',
          players: [],
        };

        for (let i in data.widgets) {
          const widget = data.widgets[i];

          if (Object.keys(widgets).indexOf(widget.slug) !== -1) {
            for (let j in widgets) {
              if (j === widget.slug) {
                widgets[j].map(({ key }, k) => {
                  widgets[j][k].value = widget[key] === null ? defaults[key] : widget[key];

                  return widgets;
                });
              }
            }
          }
        }

        context.setState({ widgets });
        context.loadWidgetsContent();
      });
  };

  render() {
    const { history, league } = this.props;
    const isCfb = league.toLowerCase() === 'cfb';
    const isMlb = league.toLowerCase() === 'mlb';

    return (
      <Container>
        {league !== 'all' ? (
          <div className="breadcrumb">
            <CustomLink to="/">Home</CustomLink>
            <em>/</em>
            <span>{league}</span>
          </div>
        ) : null}

        <section className={'news-banner ' + (league || []).toLowerCase()}>
          <HeroCarouselWidget />
          <SicInsightsWidget history={history} />
        </section>

        {isCfb ? null : (
          <>
            <HealthScoreWidget />
          </>
        )}

        {isCfb || isMlb ? null : (
          <>
            <SpecialsBar className="in-between" />
          </>
        )}

        <LatestNews
          selfLoad={false}
          showEditWidgetButton={true}
          showLeagueSwitcher={true}
          postsCount={-1}
          hasScroller={true}
          hideSignupForm={true}
        />

        {isCfb ? null : (
          <section className="main-widgets">
            <div className="x3">
              <GameFeedWidget />
            </div>
            <div className="x3">
              <h1 className="section-title mb-4">Player Updates</h1>
              <SidePostsWidget />

              {getToken() ? null : (
                <div className="cta-signup">
                  <div className="cta-signup-icon">
                    <LockIcon />
                  </div>
                  <div className="cta-signup-text">
                    SIGN UP FOR FREE
                    <br />
                    TO GET ALL THE INSIGHTS FROM SPORTS INJURY CENTRAL
                  </div>
                  <CustomLink className="cta-signup-button" to="/register">
                    Sign Up
                  </CustomLink>
                </div>
              )}
            </div>
            <div className="x2">
              <VideosFeedWidget />
            </div>
          </section>
        )}
      </Container>
    );
  }
}

HomeScreen.contextType = GlobalContext;
HomeScreen.defaultProps = {
  league: 'all',
};

export default HomeScreen;
