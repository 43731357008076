import React, { useContext, useState } from 'react';
import GlobalContext from '../contexts/GlobalContext';
import Container from '@material-ui/core/Container';
import SpecialsBar from './SpecialsBar';
import CustomLink from './CustomLink';
import LoadingWheel from './LoadingWheel';
import logo from '../images/logo-sic-wide.png';
import logoPartner1 from '../images/logo-partner-1.png';
import logoPartner2 from '../images/logo-partner-2.png';
import logoPartner3 from '../images/logo-partner-3.png';
import logoPartner4 from '../images/logo-partner-4.png';
import logoPartner5 from '../images/logo-partner-5.png';
import logoPartner6 from '../images/logo-partner-6.png';
import { CONTACT_EMAIL } from '../config.js';
import axios from 'axios';

export default function Footer() {
  const context = useContext(GlobalContext);
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  return (
    <>
      <footer className="footer">
        <div className="footer-main">
          <div className="footer-main-inner">
            <Container fixed>
              <div className="footer-links">
                <nav>
                  <ul>
                    <li className="menu-title">SITE MAP</li>
                    <li>
                      <CustomLink to="/matchups">SIC Scores</CustomLink>
                    </li>
                    <li>
                      <CustomLink to="/picks">SIC Picks</CustomLink>
                    </li>
                    <li>
                      <CustomLink to="/nfl">Pro Football Doc</CustomLink>
                    </li>
                    <li>
                      <CustomLink to="/cfb">College Football Doc</CustomLink>
                    </li>
                    <li>
                      <CustomLink to="/nba">Pro Basketball Doc</CustomLink>
                    </li>
                    <li>
                      <CustomLink to="/mlb">Pro Baseball Doc</CustomLink>
                    </li>
                    <li>
                      <CustomLink to="/about">About</CustomLink>
                    </li>
                    <li>
                      <CustomLink to="/podcasts">Podcasts</CustomLink>
                    </li>
                    <li>
                      <CustomLink to="/videos">Video</CustomLink>
                    </li>
                    <li>
                      <a href="https://newsletter.sicscore.com">Newsletter</a>
                    </li>
                  </ul>

                  <ul>
                    <li className="menu-title">FOLLOW US</li>
                    <li>
                      <a href="https://twitter.com/ProFootballDoc" target="_blank" rel="noopener noreferrer">
                        Pro Football Doc Twitter
                      </a>
                    </li>
                    <li>
                      <a href="https://twitter.com/SICscore" target="_blank" rel="noopener noreferrer">
                        Sports Injury Central Twitter
                      </a>
                    </li>
                    <li>
                      <a href="https://www.youtube.com/profootballdoc" target="_blank" rel="noopener noreferrer">
                        YouTube
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.instagram.com/profootballdoc/?hl=en"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Pro Football Doc Instagram
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.instagram.com/sportsinjurycentral/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Sports Injury Central Instagram
                      </a>
                    </li>
                    <li>
                      <a href="https://www.tiktok.com/@sicscore" target="_blank" rel="noopener noreferrer">
                        TikTok
                      </a>
                    </li>
                    <li>
                      <a href="https://www.facebook.com/Profootballdoc/" target="_blank" rel="noopener noreferrer">
                        Facebook
                      </a>
                    </li>
                    <li className="menu-title">CONTACT US</li>
                    <li>
                      Email: <a href={'mailto:' + CONTACT_EMAIL}>support@sicscore.com</a>
                    </li>
                    <li>
                      Phone: <a href="tel:+16193279538">+1 619-327-9538</a>
                    </li>
                  </ul>
                </nav>
                <div>
                  <div className="footer-form">
                    <div className="footer-form-id">
                      <img src={logo} alt="PFD logo" />
                      <div>NEWSLETTER</div>
                    </div>
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                        setError('');
                        setLoading(true);

                        axios
                          .post('https://api.convertkit.com/v3/forms/4979704/subscribe', {
                            api_key: 'GRTzY1fgr3q5ktuwbPD0Nw',
                            email,
                          })
                          .then(({ data }) => {
                            setEmail('');
                            setLoading(false);
                            setError('Thank you!');
                          })
                          .catch(({ response }) => {
                            setLoading(false);
                            setError(response.data.message);
                          });
                      }}
                    >
                      <label>
                        Get a winning edge for sports betting, DFS and fantasy with insights from our former pro team
                        doctors - free each week!
                      </label>
                      <fieldset>
                        <input
                          type="email"
                          value={email}
                          placeholder="Enter email"
                          onChange={({ target }) => setEmail(target.value)}
                        />
                        <button className="primary-button" disabled={loading}>
                          Subscribe
                        </button>
                      </fieldset>
                      {error ? <div className="footer-form-error">{error}</div> : null}
                    </form>
                  </div>
                  <div className="affiliates">
                    <ul>
                      <li>
                        <figure>
                          <a
                            href="http://wlfanduel.adsrv.eacdn.com/C.ashx?btag=a_23114b_2356c_&affid=10113&siteid=23114&adid=2356&c="
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img src={logoPartner1} alt="Partner logo" />
                          </a>
                        </figure>
                      </li>
                      <li>
                        <figure>
                          <a href="https://www.sportsgrid.com" target="_blank" rel="noopener noreferrer">
                            <img src={logoPartner2} alt="Partner logo" />
                          </a>
                        </figure>
                      </li>
                      <li>
                        <figure>
                          <a href="https://www.fantasypros.com/?partner=SIC" target="_blank" rel="noopener noreferrer">
                            <img src={logoPartner3} alt="Partner logo" />
                          </a>
                        </figure>
                      </li>
                    </ul>
                    <ul>
                      <li>
                        <figure>
                          <a href="https://www.outkick.com" target="_blank" rel="noopener noreferrer">
                            <img src={logoPartner4} alt="Partner logo" />
                          </a>
                        </figure>
                      </li>
                      <li>
                        <figure>
                          <a href="https://www.linestarapp.com" target="_blank" rel="noopener noreferrer">
                            <img src={logoPartner5} alt="Partner logo" />
                          </a>
                        </figure>
                      </li>
                      <li>
                        <figure>
                          <a href="https://sportsdata.io" target="_blank" rel="noopener noreferrer">
                            <img src={logoPartner6} alt="Partner logo" />
                          </a>
                        </figure>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="copyright">
                <span>
                  &copy; 2023. All Rights Reserved Sports Injury Central San Diego, CA{' '}
                  <CustomLink to="/privacy" style={{ color: 'white' }}>
                    Privacy Policy
                  </CustomLink>
                </span>
                <span className="clickable" onClick={() => window.scrollTo(0, 0)}>
                  Back to top
                </span>
              </div>
            </Container>
          </div>
        </div>

        {context.loading || loading ? <LoadingWheel /> : null}
      </footer>
    </>
  );
}
