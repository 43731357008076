import { SESSION_KEY, SESSION_REFRESH_KEY, SESSION_TYPE_KEY } from '../config.js';

export function setToken(token) {
  localStorage.setItem(SESSION_KEY, token);
}

export function getToken() {
  return localStorage.getItem(SESSION_KEY);
}

export function forgetToken() {
  localStorage.removeItem(SESSION_KEY);
}

export function setRefreshToken(token) {
  localStorage.setItem(SESSION_REFRESH_KEY, token);
}

export function getRefreshToken() {
  return localStorage.getItem(SESSION_REFRESH_KEY);
}

export function forgetRefreshToken() {
  localStorage.removeItem(SESSION_REFRESH_KEY);
}

export function setAdmin(flag) {
  localStorage.setItem(SESSION_TYPE_KEY, flag ? 'admin' : 'user');
}

export function isAdmin() {
  return localStorage.getItem(SESSION_TYPE_KEY) === 'admin';
}

export function getSeason() {
  return 2023;
}
